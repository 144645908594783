import React, { useState, useEffect, useRef } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import stepImg from "../../assets/Step-bro.svg";
import Topnav from "../../components/Topnav";

import { useHistory } from "react-router-dom";

import { Bar, HorizontalBar, Pie, Doughnut } from "react-chartjs-2";

import {
  FaArrowDown,
  FaArrowUp,
  FaArrowRight,
  FaCaretDown,
  FaCaretRight,
  FaCaretUp,
} from "react-icons/fa";

import api from "../../services/api";
  
import convertToChartJs from "../../utils/convertToChartJs";
import moneyAbbreviation from "../../utils/moneyAbbreviation";
import showTableImg from "../../assets/Show_table.png";
import clearFilterImg from "../../assets/clear_filter.png";
import ButtonGraphGroup from "../../components/ButtonGraphGroup";
import {backgroundColors, getOptions} from '../../utils/chartUtils';
import humanize from '../../utils/humanize';
import sortFilters from '../../utils/sortFilters';
import Downloadable from "../../components/Downloadable";
import Select from "../../components/Select";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';
import { Button } from "../SignIn/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalDatePicker from "./modal_date";



function PPRSample() {
  const MySwal = withReactContent(Swal);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    currencyDisplay: "code",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  const rangeRef = useRef();

  const { token } = useAuth();

  const history = useHistory();
  const refDates = useRef();

  const [agency, setAgency] = useState("");
  const [country, setCountry] = useState("");
  const [actualData, setActualData] = useState({});

  const [reviewTypeGraph, setReviewTypeGraph] = useState([]);
  const [marketApproach, setMarketApproach] = useState([]);
  const [procurementMethod, setProcurementMethod] = useState([]);
  const [procurementCategory, setProcurementCategory] = useState([]);

  const [filterOptions, setFilterOptions] = useState([]);

  const [graphShowType, setGraphShowType] = useState({});

  const [showFilters, setShowFilters] = useState(true);
  const [expand, setExpand] = useState('');

  const [dashboard, setDashboard] = useState({});
  const [showContentFilters,setShowContentFilters] = useState({});
  const [filterFilter, setFilterFilter] = useState({
    sampleRate: 0.2
  });
  
  const [showFakeModal, setShowFakeModal] = useState(false);

  const [lists, setLists] = useState([]);

  const [upperTable, setUpperTable] = useState([]);

  const [sendRequest, setSendRequest] = useState(false);

  
  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    api.get("/pprSamples/filterOptions").then((response) => {
      setFilterOptions(sortFilters(response.data));
    });

    loadGraph();
  }, [token]);

  function loadGraph() {
    let query = [];
    Object.keys(actualData).forEach((e) => {
      query.push(e + "=" + encodeURIComponent(actualData[e]));
    });
    query = "?" + query.join("&");
    if(query == '?'){
      query = '';
    }
    api.get("/pprSamples" + query).then((response) => {
      setLists(response.data);
      setUpperTable(response.data.sorted);
    });
  }

  useEffect(
    function () {
      if(sendRequest){
        setSendRequest(false);
        loadGraph();
      }
    },
    [sendRequest]
  );

  useEffect(
    function(){
      if(rangeRef != null && rangeRef.current){
        rangeRef.current.value = (parseInt(filterFilter['sampleRate'] * 100).toFixed(0));
      }
    }, 
    [filterFilter]
  );

  useEffect(()=>{
    if(refDates.current != null){
      refDates.current.props.onChange = (a)=>{
        refDates.current.props.range = [{
            startDate: a.selection.startDate,
            endDate: a.selection.endDate,
            key: 'selection',
          }];
        setActualData({
          ...actualData,
          strDate: a.selection.startDate,
          endDate: a.selection.endDate
        });
      };
    }
  }, [refDates]);

  return (
    <>
      <SideBar />
      <Container>
        <Topnav
          title="PPR Samples"
          // children={Object.entries(dashboard).map(([title,value])=>({title:humanize(title),value})).map((e, i) => (
          //   <div key={i} className="col-3">
          //     <div className="h-100 py-1">
          //       <div className="h-100">
          //         <div className="card h-100">
          //           <div className="card-body">
          //             <div className="row ">
          //               <div className="col  d-flex justify-content-between">
          //                 <small>{e.title}</small>
          //                 {/* </div>
          //               <div className="col-6 text-end"> */}
          //                 <h6>{moneyAbbreviation(e.value)}</h6>
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // ))}
        />
        <div className="container-fluid">
          <div className="row ms-2">
            <div class="col-9">
              <div class="row">
                <div className="col-12 text-start">
                  {/* {showFilters && (
                    <FaCaretDown
                      size={30}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowFilters(false);
                      }}
                    />
                  )}
                  {!showFilters && (
                    <FaCaretUp
                      size={30}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowFilters(true);
                      }}
                    />
                  )} */}
                  
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <h4>Selected table</h4>
                    <div className='table'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (upperTable ?? []).map((e,i)=><tr key={i}>
                              <td>
                                {e._id}
                              </td>
                              <td>
                                {e.name}
                              </td>
                              <td>
                                <FaArrowDown 
                                  style={{cursor:'pointer'}}
                                  title="Move to bottom table"
                                  onClick={()=>{
                                    let uTable = upperTable.filter((c)=>c._id != e._id);
                                    setUpperTable(Array.from(uTable));
                                  }}
                                />
                              </td>
                            </tr>)
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='col-12'>
                    <h4>Unselected table</h4>
                    <div className='table'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (lists.original ?? []).filter((e)=>!upperTable.find((c)=>c._id == e._id)).map((e,i)=><tr key={i}>
                              <td>
                                {e._id}
                              </td>
                              <td>
                                {e.name}
                              </td>
                              <td>
                                <FaArrowUp
                                  style={{cursor:'pointer'}}
                                  title="Move to upper table"
                                  onClick={()=>{
                                    if(!upperTable.find((c)=>c._id == e._id)){
                                      upperTable.push(e);
                                    }
                                    setUpperTable(Array.from(new Set(upperTable)));
                                  }}
                                />
                              </td>
                            </tr>)
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-3'>
                <div className='col-12'>
                    <div className='row'>
                      {showFilters &&
                        <div className="col-12 d-flex my-auto">
                          <div className="row " style={{}}>
                            <div className="col">
                              <button
                                className="btn  btn-outline-info "
                                title="Clear filters"
                                onClick={() => {
                                  setActualData({});
                                  rangeRef.current.value = (0.2);
                                  setFilterFilter({
                                    ...filterFilter,
                                    sampleRate: 0.2
                                  })
                                }}
                              >
                                <img src={clearFilterImg} style={{ width: "20px" }}></img>
                              </button>
                              <Downloadable
                                path={'pprSamples'}
                                method="POST"
                                data={{
                                  projects: upperTable.map((e)=>e._id)
                                }}
                                innerGet={(Object.keys(actualData).map((e) => {
                                  return e + "=" + (Array.isArray(actualData[e])?actualData[e].join('^'):actualData[e]);
                                })).join('&')}
                              />
                            </div>
                          </div>
                        </div>
                      }
                      {showFilters &&
                        filterOptions &&
                        Object.entries(filterOptions)
                          .map(([key, val]) => {
                            return {
                              key: key,
                              get: function () {
                                let c = actualData || {};
                                let r = c[key];
                                return r || "";
                              },
                              set: function (c) {
                                let d = actualData || {};
                                d[key] = c.target.value.map((e)=>e.value).join('^');
                                if (!d[key]) {
                                  delete d[key];
                                }
                                setActualData({ ...d });
                              },
                              options: val.map((e) => {
                                return {
                                  value: e?.projectId ?? e?.agencyId ?? e?.name ?? e,
                                  label: e?.projectId ?? e?.name ?? e,
                                };
                              }),
                              title: humanize(key),
                              selectPl: humanize(key),
                            };
                          })
                          .map((e, i) => (
                            <div key={i} className="col-12">
                              <div className='row my-3' style={{cursor:'pointer'}}
                                    onClick={()=>{
                                      let obj = {...showContentFilters};
                                      obj[e.selectPl] = !obj[e.selectPl];
                                      setShowContentFilters(obj);
                                    }}>
                                <div className='col-9'>
                                  <h6>{e.selectPl}</h6>
                                </div>
                                <div className='col'>
                                  <span
                                    
                                  >
                                    {
                                      showContentFilters[e.selectPl] ? 
                                      <FaArrowUp /> : 
                                      <FaArrowDown />
                                    }
                                  </span>
                                </div>
                              </div>

                              <div style={{
                                display: showContentFilters[e.selectPl] ? 'block':'none',
                                height:'300px', overflowY:'auto', overflowX:'hidden', wordBreak: 'break-all',
                              }}>
                                <input
                                  type="text"
                                  className='form-control'
                                  placeholder="Filter"
                                  onKeyUp={(c)=>{
                                    let f = {...filterFilter};
                                    f[e.key] = c.target.value;
                                    setFilterFilter(f);
                                  }}
                                />
                                {
                                  e.options
                                  .filter((c)=> !filterFilter[e.key] || c.label.toLowerCase().includes(filterFilter[e.key].toLowerCase()))
                                  .map((c,i)=>{
                                    let d = (e.get().split('^')).filter((e)=>!!e);
                                    return <div key={i} className='my-1'>
                                      <input type='checkbox' onChange={(s)=>{
                                        if(s.target.checked){
                                          if(c.value){
                                            let vn = [c];
                                            //  d.map((e)=>({value: e}));
                                            // vn.push(c);
                                            e.set({target:{value:vn}});
                                          }
                                        }else{
                                          d = d.filter((e)=> e != c.value);
                                          e.set({target:{value:d.map((e)=>({value: e}))}});
                                        }
                                      }} checked={(d.includes(c.value.toString()) ? 'checked': '')} ></input>
                                      &nbsp;&nbsp;{c.label}
                                    </div>;
                                  })
                                }
                              </div>
                            </div>
                          ))}

                      {showFilters && 
                        <>
                        <div className="col-12">
                                <div className='row my-3' style={{cursor:'pointer'}}
                                      onClick={()=>{
                                        let obj = {...showContentFilters};
                                        obj['sampleRate'] = !obj['sampleRate'];
                                        setShowContentFilters(obj);
                                        
                                      }}>
                                  <div className='col-9'>
                                    <h6>Sample rate</h6>
                                  </div>
                                  <div className='col'>
                                    <span
                                      
                                    >
                                      {
                                        showContentFilters['sampleRate'] ? 
                                        <FaArrowUp /> : 
                                        <FaArrowDown />
                                      }
                                    </span>
                                  </div>
                                </div>

                                <div style={{
                                  display: showContentFilters['sampleRate'] ? 'block':'none',
                                  height:'50px', overflowY:'auto', overflowX:'hidden', wordBreak: 'break-all',
                                }}>
                                  <div className='row'>
                                    <div className='col-8'>
                                      <input 
                                        type='range' 
                                        min={0} 
                                        max={100} 
                                        style={{width:'100%'}}
                                        value={(parseInt(filterFilter['sampleRate'] * 100).toFixed(0))}
                                        onChange={(v)=>{
                                          let val = Math.round(Number(v.target.value));
                                          if(val >= 20 && val <= 100){
                                            let f = {...filterFilter};
                                            f['sampleRate'] = (val/100);
                                            setFilterFilter(f);

                                            let d = actualData || {};
                                            d['sampleRate'] = f['sampleRate'];
                                            if (!d['sampleRate']) {
                                              delete d['sampleRate'];
                                            }
                                            setActualData({ ...d });
                                            rangeRef.current.value = (parseInt(filterFilter['sampleRate'] * 100).toFixed(0));
                                          }
                                        }}></input>
                                    </div>
                                    <div className="col-4">
                                      <input 
                                        type='number'
                                        ref={rangeRef}
                                        min={20}
                                        max={100}
                                        style={
                                          {
                                            width: "55%",
                                            border: "1px solid grey",
                                            background: "transparent",
                                            borderRadius: "3px",
                                          }
                                        }
                                        defaultValue={(parseInt(filterFilter['sampleRate'] * 100).toFixed(0))}
                                        onChange={(v)=>{
                                          let val = Math.round(Number(v.target.value));
                                          if(val >= 20 && val <= 100){
                                            let f = {...filterFilter};
                                            f['sampleRate'] = (val/100);
                                            setFilterFilter(f);

                                            let d = actualData || {};
                                            d['sampleRate'] = f['sampleRate'];
                                            if (!d['sampleRate']) {
                                              delete d['sampleRate'];
                                            }
                                            setActualData({ ...d });
                                          }
                                        }}
                                        onKeyUp={(v)=>{
                                          let val = Math.round(Number(v.target.value));
                                          if(val >= 20 && val <= 100){
                                            let f = {...filterFilter};
                                            f['sampleRate'] = (val/100);
                                            setFilterFilter(f);

                                            let d = actualData || {};
                                            d['sampleRate'] = f['sampleRate'];
                                            if (!d['sampleRate']) {
                                              delete d['sampleRate'];
                                            }
                                            setActualData({ ...d });
                                          }
                                        }}
                                      ></input>
                                      %
                                    </div>
                                  </div>
                                </div>

                                <div className="text-center">
                                  <button className="btn btn-primary mx-auto"
                                    onClick={()=>{
                                      setSendRequest(true);
                                    }}
                                  >
                                    Confirm
                                  </button>
                                </div>

                              </div>
                          
                        </>
                      }
                    </div>
                </div>
              </div>
          </div>



          
        </div>
      </Container>
    </>
  );
}

export default PPRSample;
