import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { FaFileExcel, FaFilePdf, FaFileCsv, FaCircleNotch } from "react-icons/fa";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function Downloadable({
    innerGet, 
    path, 
    enablePdf=false, 
    enableCsv=true, 
    method='GET',
    data=[]
}){
    const { token } = useAuth();
    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState({});

    useEffect(() => {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }, [token]);

    async function handleError(data){
        let f = JSON.parse(await data.text());
        MySwal.fire('Ops!',f.message,'error');
    }

    function download(format){
        setLoading({...{[format]:true}});
        api({
            url: path+'/download?format='+format+(innerGet.trim() ? '&'+innerGet:''), //your url
            method: method,
            responseType: 'blob', // important
            data: data
        }).then((response) => {
            let s = {...loading};
            delete s[format];
            setLoading(s);
            if(response.status == 200){

                let f = response.headers['content-disposition'] ? response.headers['content-disposition'].split('filename=')[1]:null;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let format = response.headers['content-type'].includes('spreadsheet') ? 'xlsx':'csv';
                link.setAttribute('download', f ? f: path+"."+format); //or any other extension
                document.body.appendChild(link);
                link.click();
            }else{
                if(response && response.data){
                    handleError(response.data);
                }
            }
        }).catch((e)=>{
            let s = {...loading};
            delete s[format];
            setLoading(s);
            if(e.response && e.response.data){
                handleError(e.response.data);
            }
        });
    }

    return <>
        <button
            className="btn btn-outline-link"
            title="Download .xlsx"
            onClick={() => {
                download('xlsx');
            }}
        >
            {
                loading['xlsx'] &&
                <FaCircleNotch size={25} className='step-spin' />
            }
            {
                !loading['xlsx'] && 
                <FaFileExcel size={25} color={'green'} />
            }
        </button>
        {
            enableCsv && 
            <button
                className="btn btn-outline-link"
                title="Download .csv"
                onClick={() => {
                    download('csv');
                }}
            >
                {
                    loading['csv'] &&
                    <FaCircleNotch size={25} className='step-spin' />
                }
                {
                    !loading['csv'] && 
                    <FaFileCsv size={25} color={'#30503A'} />
                }
            </button>
        }
        {
            enablePdf &&
            <button
                className="btn btn-outline-link"
                title="Download .pdf"
                onClick={() => {
                    download('pdf');
                }}
            >
                <FaFilePdf size={25} color={'red'} />
            </button>
        }
    </>;
};