import React, { useState, useEffect, useRef } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import stepImg from "../../assets/Step-bro.svg";
import Topnav from "../../components/Topnav";

import { useHistory } from "react-router-dom";

import { Bar, HorizontalBar, Pie, Doughnut } from "react-chartjs-2";

import {
  FaArrowDown,
  FaArrowUp,
  FaArrowRight,
  FaCaretDown,
  FaCaretRight,
  FaCaretUp,
} from "react-icons/fa";

import api from "../../services/api";
  
import convertToChartJs from "../../utils/convertToChartJs";
import moneyAbbreviation from "../../utils/moneyAbbreviation";
import showTableImg from "../../assets/Show_table.png";
import clearFilterImg from "../../assets/clear_filter.png";
import ButtonGraphGroup from "../../components/ButtonGraphGroup";
import {backgroundColors, getOptions} from '../../utils/chartUtils';
import humanize from '../../utils/humanize';
import sortFilters from '../../utils/sortFilters';
import Downloadable from "../../components/Downloadable";
import Select from "../../components/Select";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';
import { Button } from "../SignIn/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalDatePicker from "./modal_date";



function Acitivities() {
  const MySwal = withReactContent(Swal);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    currencyDisplay: "code",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  const { token } = useAuth();

  const history = useHistory();
  const refDates = useRef();

  const [agency, setAgency] = useState("");
  const [country, setCountry] = useState("");
  const [actualData, setActualData] = useState({});

  const [reviewTypeGraph, setReviewTypeGraph] = useState([]);
  const [marketApproach, setMarketApproach] = useState([]);
  const [procurementMethod, setProcurementMethod] = useState([]);
  const [procurementCategory, setProcurementCategory] = useState([]);

  const [filterOptions, setFilterOptions] = useState([]);

  const [graphShowType, setGraphShowType] = useState({});

  const [showFilters, setShowFilters] = useState(true);
  const [expand, setExpand] = useState('');

  const [dashboard, setDashboard] = useState({});
  const [showContentFilters,setShowContentFilters] = useState({});
  const [filterFilter, setFilterFilter] = useState({});
  
  const [showFakeModal, setShowFakeModal] = useState(false);

  
  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    api.get("/contracts/filterOptions").then((response) => {
      setFilterOptions(sortFilters(response.data));
    });

    // api.get('/contracts').then(response => {

    // })

    loadGraph();
  }, [token]);

  function loadGraph() {
    let query = [];
    Object.keys(actualData).forEach((e) => {
      query.push(e + "=" + encodeURIComponent(actualData[e]));
    });
    query = "?" + query.join("&");
    if(query == '?'){
      query = '';
    }
    api.get("/contracts/graph/procurementCategory" + query).then((response) => {
      setProcurementCategory(convertToChartJs(response.data));
    });

    api.get("/contracts/graph/procurementMethod" + query).then((response) => {
      setProcurementMethod(convertToChartJs(response.data));
    });

    api.get("/contracts/graph/suppliersRank" + query).then((response) => {
      setMarketApproach((response.data));
    });

    api.get("/contracts/graph/reviewType" + query).then((response) => {
      setReviewTypeGraph(convertToChartJs(response.data));
    });

    api.get("/contracts/dashboard"+query).then((response) => {
      setDashboard(response.data);
    });
  }

  useEffect(
    function () {
      loadGraph();
    },
    [actualData]
  );

  useEffect(()=>{
    if(refDates.current != null){
      refDates.current.props.onChange = (a)=>{
        refDates.current.props.range = [{
            startDate: a.selection.startDate,
            endDate: a.selection.endDate,
            key: 'selection',
          }];
        setActualData({
          ...actualData,
          strDate: a.selection.startDate,
          endDate: a.selection.endDate
        });
      };
    }
  }, [refDates]);

  return (
    <>
      <SideBar />
      <Container>
        <Topnav
          title="Contracts"
          children={Object.entries(dashboard).map(([title,value])=>({title:humanize(title),value})).map((e, i) => (
            <div key={i} className="col-3">
              <div className="h-100 py-1">
                <div className="h-100">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row ">
                        <div className="col  d-flex justify-content-between">
                          <small>{e.title}</small>
                          {/* </div>
                        <div className="col-6 text-end"> */}
                          <h6>{moneyAbbreviation(e.value)}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        />
        <div className="container-fluid">
          <div className="row ms-2">
            <div className="col-12 text-start">
              {/* {showFilters && (
                <FaCaretDown
                  size={30}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowFilters(false);
                  }}
                />
              )}
              {!showFilters && (
                <FaCaretUp
                  size={30}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowFilters(true);
                  }}
                />
              )} */}
              
            </div>
            <div className='row'>
              <div className='col-10'>
              <div className="row text-center ">
            <div className={(expand == 'procurementCategory'?'col-10':'col-6')+" mx-auto text-center my-4 animatedContainer"}>
              <div className="alert alert-secondary">
                <div className="row">
                  <div className="col my-auto">
                    <h5 className="my-auto">Procurement Category </h5>
                  </div>
                  <div className="col-3">
                    <div className="btn-group">
                    <ButtonGraphGroup
                        inState={graphShowType.procurementCategory == "$"}
                        button1OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            procurementCategory: "#",
                          });
                          setGraphShowType({ ...x });
                        }}
                        button2OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            procurementCategory: "$",
                          });
                          setGraphShowType({ ...x });
                        }}
                        expandButtonOnClick={()=>{
                          setExpand(expand == 'procurementCategory'?'':'procurementCategory')
                        }}
                      />
                      
                    </div>
                  </div>
                </div>
              </div>
              <div style={{
                position: 'relative',
                width: '100%'
              }}>
                <Bar
                  data={{
                    labels: procurementCategory.map((e) => e.label.split(" ")),
                    datasets: [
                      {
                        label: "",
                        data: procurementCategory.map((e) =>
                          graphShowType.procurementCategory == "$"
                            ? e.monetaryValue
                            : e.value
                        ),
                        backgroundColor: backgroundColors(),
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={Object.assign(
                    {showAllTooltips: expand == 'procurementCategory'},getOptions(graphShowType.procurementCategory == "$"))}
                />
              </div>
            </div>

            <div className={(expand == 'procurementMethod'?'col-10':'col-6')+" mx-auto text-center  my-4 animatedContainer"}>
              <div className="alert alert-secondary">
                <div className="row">
                  <div className="col my-auto">
                    <h5 className="my-auto">Procurement Method </h5>
                  </div>
                  <div className="col-3">
                    <div className="btn-group">
                    <ButtonGraphGroup
                        inState={graphShowType.procurementMethod == "$"}
                        button1OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            procurementMethod: "#",
                          });
                          setGraphShowType({ ...x });
                        }}
                        button2OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            procurementMethod: "$",
                          });
                          setGraphShowType({ ...x });
                        }}
                        expandButtonOnClick={()=>{
                          setExpand(expand == 'procurementMethod'?'':'procurementMethod')
                        }}
                      />
                      
                    </div>
                  </div>
                </div>
              </div>
              <div style={{
                position: 'relative',
                width: '100%'
              }}>

                <HorizontalBar
                  data={{
                    labels: procurementMethod.map((e) => e.label),
                    datasets: [
                      {
                        label: "",
                        data: procurementMethod.map((e) =>
                          graphShowType.procurementMethod == "$"
                            ? e.monetaryValue
                            : e.value
                        ),
                        backgroundColor: backgroundColors(),
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={Object.assign(
                    {showAllTooltips: expand == 'procurementMethod'},getOptions(graphShowType.procurementMethod == "$"))}
                />
              </div>
            </div>

            <div className={(expand == 'reviewType'?'col-10':'col-6')+" mx-auto text-center  my-4 animatedContainer"}>
              <div className="alert alert-secondary">
                <div className="row">
                  <div className="col my-auto">
                    <h5 className="my-auto">Review Type</h5>
                  </div>
                  <div className="col-3">
                    <div className="btn-group">
                    <ButtonGraphGroup
                        inState={graphShowType.reviewType == "$"}
                        button1OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            reviewType: "#",
                          });
                          setGraphShowType({ ...x });
                        }}
                        button2OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            reviewType: "$",
                          });
                          setGraphShowType({ ...x });
                        }}
                        expandButtonOnClick={()=>{
                          setExpand(expand == 'reviewType'?'':'reviewType')
                        }}
                      />
                      
                    </div>
                  </div>
                </div>
              </div>
              <div style={{
                position: 'relative',
                width: '100%'
              }}>

                <Doughnut
                  data={{
                    labels: reviewTypeGraph.filter(e=>!["None Review", "Total"].includes(e.label)).map((e) => e.label),
                    datasets: [
                      {
                        label: "",
                        data: reviewTypeGraph.filter(e=>!["None Review", "Total"].includes(e.label)).map((e) =>
                          graphShowType.reviewType == "$"
                            ? e.monetaryValue
                            : e.value
                        ),
                        backgroundColor: backgroundColors(),
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={{
                    ...Object.assign(
                      {showAllTooltips: expand == 'reviewType'},getOptions(graphShowType.reviewType == "$", true), {
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              display: false,
                            },
                            gridLines: {
                              display: false,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              display: false,
                            },
                            gridLines: {
                              display: false,
                            },
                          },
                        ],
                      },
                    }),
                  }}
                />
              </div>
            </div>

            <div className={(expand == 'marketApproach'?'col-10':'col-6')+"  mx-auto text-center  my-4 animatedContainer"}>
              <div className="alert alert-secondary">
                <div className="row">
                  <div className="col my-auto">
                    <h5 className="my-auto">Supplier's Rank </h5>
                  </div>
                  <div className="col-3">
                    <div className="btn-group">
                    <ButtonGraphGroup
                        inState={graphShowType.marketApproach == "$"}
                        button1Hide={true}
                        button2Hide={true}
                        button1OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            marketApproach: "#",
                          });
                          setGraphShowType({ ...x });
                        }}
                        button2OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            marketApproach: "$",
                          });
                          setGraphShowType({ ...x });
                        }}
                        expandButtonOnClick={()=>{
                          setExpand(expand == 'marketApproach'?'':'marketApproach')
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{
                position: 'relative',
                width: '100%'
              }}>
                <HorizontalBar
                  data={{
                    labels: marketApproach.map((e,i) => {
                      if(expand == 'marketApproach'){
                        let s = e._id.split(" ");
                        let limit = 2;
                        let cnt = '';
                        let res = [];
                        for(let i = 0; i < s.length; i++){
                          cnt += " " + s[i];
                          if(i == limit){
                            res.push(cnt);
                            cnt = '';
                          }
                        }
                        if(cnt.length > 0){
                          res.push(cnt);
                        }
                        return res;
                      }else{
                        return (i+1).toString();
                      }
                    }),
                    datasets: [
                      {
                        label: "",
                        data: marketApproach.map((e) =>e.count),
                        backgroundColor: backgroundColors(),
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={Object.assign(
                    {showAllTooltips: expand == 'marketApproach'},getOptions(graphShowType.marketApproach == "$", false, marketApproach.map((e,i) => {
                    return e._id;
                  })))}
                />
              </div>
            </div>
          </div>

          {
            showFakeModal &&
            <div className='fakeModal' onClick={()=>{
              // setShowFakeModal(!showFakeModal);
              // let obj = {...showContentFilters};
              // obj['dateRange'] = !obj['dateRange'];
              // setShowContentFilters(obj);
            }}>
              <div className='fakeModalContent' onClick={()=>{}}>
                <DateRange
                      ranges={[{
                        startDate: actualData.strDate ? new Date(Date.parse(actualData.strDate+"T00:00:01")) : new Date(),
                        endDate: actualData.endDate ? new Date(Date.parse(actualData.endDate+"T00:00:01")) : new Date(),
                        key: 'selection',
                      }]}
                      onChange={(newValue)=>{
                        if(newValue.selection.startDate && newValue.selection.endDate){
                          console.log(newValue);
                          let obj = {...showContentFilters};
                          obj['dateRange'] = !obj['dateRange'];
                          setShowContentFilters(obj);
                          setActualData(Object.assign({},{
                            // ...actualData,
                            strDate: newValue.selection.startDate.toISOString().split("T")[0],
                            endDate: newValue.selection.endDate.toISOString().split("T")[0],
                          }));
                        }
                      }}
                  />

                  <Button type="submit" onClick={()=>{
                    setShowFakeModal(!showFakeModal);
                    let obj = {...showContentFilters};
                    obj['dateRange'] = !obj['dateRange'];
                    setShowContentFilters(obj);
                  }}>OK</Button>
                
              </div>
            </div>
          }

              </div>
              <div className='col-2'>
                <div className='col-11'>
                    <div className='row'>
                      {showFilters &&
                        <div className="col-12 d-flex align-self-center my-auto">
                          <div className="row " style={{}}>
                            <div className="col">
                              <button
                                className="btn  btn-outline-info "
                                title="Clear filters"
                                onClick={() => {
                                  setActualData({});
                                }}
                              >
                                <img src={clearFilterImg} style={{ width: "20px" }}></img>
                              </button>
                              <Downloadable
                                path={'contracts'}
                                innerGet={(Object.keys(actualData).map((e) => {
                                  return e + "=" + (Array.isArray(actualData[e])?actualData[e].join('^'):actualData[e]);
                                })).join('&')}
                              />
                            </div>
                          </div>
                        </div>
                      }

                      {showFilters && 
                      <>
                      <div className="col-12">
                              <div className='row my-3' style={{cursor:'pointer'}}
                                    onClick={()=>{
                                      let obj = {...showContentFilters};
                                      obj['dateRange'] = !obj['dateRange'];
                                      setShowContentFilters(obj);
                                      setShowFakeModal(!showFakeModal);
                                      
                                    }}>
                                <div className='col-9'>
                                  <h6>Start/End date</h6>
                                </div>
                                <div className='col'>
                                  <span
                                    
                                  >
                                    {
                                      showContentFilters['dateRange'] ? 
                                      <FaArrowUp /> : 
                                      <FaArrowDown />
                                    }
                                  </span>
                                </div>
                              </div>

                              {/* <div style={{
                                display: showContentFilters['dateRange'] ? 'block':'none',
                                height:'300px', overflowY:'auto', overflowX:'hidden', wordBreak: 'break-all',
                              }}>
                                
                              </div> */}
                            </div>
                        
                      </>
                      }

                      {showFilters &&
                        filterOptions &&
                        Object.entries(filterOptions)
                          .map(([key, val]) => {
                            return {
                              key: key,
                              get: function () {
                                let c = actualData || {};
                                let r = c[key];
                                return r || "";
                              },
                              set: function (c) {
                                let d = actualData || {};
                                d[key] = c.target.value.map((e)=>e.value).join('^');
                                if (!d[key]) {
                                  delete d[key];
                                }
                                setActualData({ ...d });
                              },
                              options: val.map((e) => {
                                return {
                                  value: e?.projectId ?? e?.agencyId ?? e?.name ?? e,
                                  label: e?.projectId ?? e?.name ?? e,
                                };
                              }),
                              title: humanize(key),
                              selectPl: humanize(key),
                            };
                          })
                          .map((e, i) => (
                            <div key={i} className="col-12">
                              <div className='row my-3' style={{cursor:'pointer'}}
                                    onClick={()=>{
                                      let obj = {...showContentFilters};
                                      obj[e.selectPl] = !obj[e.selectPl];
                                      setShowContentFilters(obj);
                                    }}>
                                <div className='col-9'>
                                  <h6>{e.selectPl}</h6>
                                </div>
                                <div className='col'>
                                  <span
                                    
                                  >
                                    {
                                      showContentFilters[e.selectPl] ? 
                                      <FaArrowUp /> : 
                                      <FaArrowDown />
                                    }
                                  </span>
                                </div>
                              </div>

                              <div style={{
                                display: showContentFilters[e.selectPl] ? 'block':'none',
                                height:'300px', overflowY:'auto', overflowX:'hidden', wordBreak: 'break-all',
                              }}>
                                <input
                                  type="text"
                                  className='form-control'
                                  placeholder="Filter"
                                  onKeyUp={(c)=>{
                                    let f = {...filterFilter};
                                    f[e.key] = c.target.value;
                                    setFilterFilter(f);
                                  }}
                                />
                                {
                                  e.options
                                  .filter((c)=> !filterFilter[e.key] || c.label.toLowerCase().includes(filterFilter[e.key].toLowerCase()))
                                  .map((c,i)=>{
                                    let d = (e.get().split('^')).filter((e)=>!!e);
                                    return <div key={i} className='my-1'>
                                      <input type='checkbox' onChange={(s)=>{
                                        if(s.target.checked){
                                          if(c.value){
                                            let vn = d.map((e)=>({value: e}));
                                            vn.push(c);
                                            e.set({target:{value:vn}});
                                          }
                                        }else{
                                          d = d.filter((e)=> e != c.value);
                                          e.set({target:{value:d.map((e)=>({value: e}))}});
                                        }
                                      }} checked={(d.includes(c.value.toString()) ? 'checked': '')} ></input>
                                      &nbsp;&nbsp;{c.label}
                                    </div>;
                                  })
                                }
                              </div>
                            </div>
                          ))}
                    </div>
                </div>
              </div>
            </div>
          </div>



          
        </div>
      </Container>
    </>
  );
}

export default Acitivities;
