export default {
    beforeRender: function (chart) {
      if (chart.config.options.showAllTooltips) {
          // create an array of tooltips
          // we can't use the chart tooltip because there is only one tooltip per chart
          chart.pluginTooltips = [];
          chart.config.data.datasets.forEach(function (dataset, i) {
              chart.getDatasetMeta(i).data.forEach(function (sector, j) {
                let type = chart.config.type;
                console.log(sector)
                let targetCalc = type == 'bar'? sector._model.width/4.5: type == 'horizontalBar' ? 7 : 0;
                let add = {

                };
                if(type == 'horizontalBar'){
                    add = {
                        titleFontSize:0,
                        titleSpacing:0,
                        bodyFontSize:12
                    }
                }
                let tool = new window.Chart.Tooltip({
                    _chart: chart.chart,
                    _chartInstance: chart,
                    _data: chart.data,
                    _options: Object.assign(chart.options.tooltips, {
                        caretPadding: (targetCalc * -1),
                    }, add),
                    _active: [sector],
                }, chart);
                chart.pluginTooltips.push(tool);
              });
          });
  
          // turn off normal tooltips
          chart.options.tooltips.enabled = false;
      }
  },
    afterDraw: function (chart, easing) {
      if (chart.config.options.showAllTooltips) {
          // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
          if (!chart.allTooltipsOnce) {
              if (easing !== 1)
                  return;
              chart.allTooltipsOnce = true;
          }
  
          // turn on tooltips
          chart.options.tooltips.enabled = true;
          window.Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
              tooltip.initialize();
              tooltip.update();
              // we don't actually need this since we are not animating tooltips
              tooltip.pivot();
              tooltip.transition(easing).draw();
          });
          chart.options.tooltips.enabled = false;
      }
    }
  };