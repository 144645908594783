function stripDuplicated(vals){
  if(vals[0]?.agencyId != null){
    return vals.filter((thing, index, self) =>
      thing &&
      index === self.findIndex((t) => (
        t?.agencyId === thing?.agencyId
      ))
    )
  }else{
    return vals.filter((e)=>e);
  }
}

export function sortValues(vals){
  return vals.sort(function(a,b){
    let av = a?.projectId ?? a?.name ?? a;
    let bv = b?.projectId ?? b?.name ?? b;
    if(av == null){
      return -1;
    }
    if(bv == null){
      return -1;
    }
    let r = (av+'').toString().trim().localeCompare((bv+'').toString().trim(),'en', { 
      sensitivity: 'base',
      ignorePunctuation:true ,
      numeric: !Number.isNaN(av) && !Number.isNaN(bv)
    });
    return r;
  });
}
export default function(unordered){
    if(!unordered){
        return unordered;
    }else{
        return Object.keys(unordered).sort().reduce(
            (obj, key) => { 
              obj[key] = sortValues(stripDuplicated(unordered[key])); 
              return obj;
            }, 
            {}
          ); 
    }
}
