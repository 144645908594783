import React from "react";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useHistory } from "react-router-dom";

import Input from "../../components/Input";
import { initialValues } from "./formikProps";

import { useAuth } from "../../hooks/auth";
import { Container, AnimationContainer, Button } from "./styles";
import api from "../../services/api";

import logoImg from "../../assets/stepx.png";

const SignUp = () => {
  const MySwal = withReactContent(Swal);

  const { SignUp } = useAuth();
  const history = useHistory();

  async function handleSubmit(values) {
    api.post('/users', {
      nome: values.firstName + " " + values.lastName,
      email: values.email
    }).then((e) => {
      MySwal.fire("Password sent", "Check your e-mail to complete your sign up.", 'success');
    }).catch((e) => {
      MySwal.fire("Invalid credentials", "Check if is a valid World Bank e-mail or if your already have a e-mail authentication in your inbox.", 'error');
    })
  }

  return (
    <Container>
      <AnimationContainer>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <img src={logoImg} alt="STEP-X" style={{ width: "50%" }} />
        <Formik
          onSubmit={(values) => handleSubmit(values)}
          validateOnMount
          initialValues={initialValues}
        >
          <Form>
            <p>
              Your password will be sent to your World Bank e-mail and you must access it in order to complete your sign up.
            </p>
            <Field as={Input} name="email" placeholder="E-mail" />

            <Field
              as={Input}
              name="firstName"
              type="text"
              placeholder="First Name"
            />

            <Field
              as={Input}
              name="lastName"
              type="text"
              placeholder="Last Name"
            />


            <Button type="submit">Sign Up</Button>
          </Form>
        </Formik>

        <Link to="/">Back to Login</Link>

      </AnimationContainer>
    </Container>
  );
};

export default SignUp;
