import React from 'react';

import { Link } from 'react-router-dom';

import { Container, AnimationContainer, Title } from './styles';

import noMatchImg from '../../assets/404-page-not-found-en.png';

const NoMatch = () => {
  return (
    <Container>
      <AnimationContainer>
        <img src={noMatchImg} alt='cold' />
        <Link to="/">Home</Link>
      </AnimationContainer>
    </Container>
  );
};

export default NoMatch;