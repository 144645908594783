import ChartJSPluginTooltipAlwaysShow from '../components/ChartJSPluginTooltipAlwaysShow/ChartJSPluginTooltipAlwaysShow';
import moneyAbbreviation from './moneyAbbreviation';
import * as chartJs from 'chart.js';

function backgroundColors(){
    return [
        // COLOR FROM LOGO
        "#F7942E",
        "#21BBAF",
        "#5C449A",
        "#414143",
        // COLOR FROM ANYWHERE
        "#2be7d5",
        "#976945",
        "#214ed5",
        "#d249ae",
        "#cc8963",
        "#ad59b5",
        "#bd759a",
        "#d4f2d8",
        '#B59676',
        '#9B9793',
        "#B4B121",
        "#5AD811",
        "#5A3611",
        "#B46C21",
        "#908BB9",
        "#C7795D",
        "#A26600",
        "#829000"
      ];
}

function getOptions(formatMoney = false,legend=false, abbrv=null) {
  chartJs.Chart.plugins.register(ChartJSPluginTooltipAlwaysShow);
  let options = {
    
  };
  let x = {
    legend: {
      display: legend
    },
    responsive: true,
    maintainAspectRatio: true,
  };
  if (formatMoney) {
    x.tooltips = {
      callbacks: {
        label: function (tooltipItem, data) {
          let d =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return moneyAbbreviation(d);
        },
      },
    };

    x.scales = {
      yAxes: [
        {
          ticks: {
            maxRotation: 0,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return moneyAbbreviation(value);
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            maxRotation: 0,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return moneyAbbreviation(value);
            },
          },
        },
      ],
    };
  } else {
    x.scales = {
      yAxes: [
        {
          ticks: {
            maxRotation: 0,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return value;
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            maxRotation: 0,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return value;
            },
          },
        },
      ],
    };
  }

  if(abbrv){
    x.tooltips = {
      callbacks: {
        label: function (tooltipItem, data) {
          let d = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return d;
        },
        title: function(tooltipItem, data){
          return tooltipItem[0].label+":"+abbrv[tooltipItem[0].index];
        },
      },
    };
  }
  return { ...Object.assign(options, x) };
}

export {
    backgroundColors,
    getOptions
} 